// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
  /* backdrop-filter: blur(2px); */
  /* height: 70%;
      width: 50%; */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: -2px -2px 4px 0px rgba(228, 228, 228, 0.3) inset,
    2px 2px 4px 0px rgba(228, 228, 228, 0.5) inset;
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  height: 500px;
  width: 500px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border: 1px solid #ffff;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0 auto;
  flex-direction: column;
  /* padding: 10px; */
  padding-right: 50px;
  padding-left: 50px;
}

.full-name-div {
  display: flex;
  justify-content: space-between;
}

.last-name-input-field{
    width: 96%;
}
@media screen and (max-width: 804px) {
  .signup-container {
    height: 80%;
    width: 80%;
    /* flex-direction: column; */
  }
  /* .full-name-div{
        flex-direction: column;

    } */
}
`, "",{"version":3,"sources":["webpack://./src/components/Signup/SignupPage.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC;mBACiB;EACjB,oCAAoC;EACpC;kDACgD;EAChD,mCAA2B;UAA3B,2BAA2B;EAC3B,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;IACI,UAAU;AACd;AACA;EACE;IACE,WAAW;IACX,UAAU;IACV,4BAA4B;EAC9B;EACA;;;OAGK;AACP","sourcesContent":[".signup-container {\n  /* backdrop-filter: blur(2px); */\n  /* height: 70%;\n      width: 50%; */\n  background: rgba(255, 255, 255, 0.2);\n  box-shadow: -2px -2px 4px 0px rgba(228, 228, 228, 0.3) inset,\n    2px 2px 4px 0px rgba(228, 228, 228, 0.5) inset;\n  backdrop-filter: blur(12px);\n  height: 500px;\n  width: 500px;\n  display: flex;\n  justify-content: center;\n  /* align-items: center; */\n  border: 1px solid #ffff;\n  box-sizing: border-box;\n  border-radius: 20px;\n  margin: 0 auto;\n  flex-direction: column;\n  /* padding: 10px; */\n  padding-right: 50px;\n  padding-left: 50px;\n}\n\n.full-name-div {\n  display: flex;\n  justify-content: space-between;\n}\n\n.last-name-input-field{\n    width: 96%;\n}\n@media screen and (max-width: 804px) {\n  .signup-container {\n    height: 80%;\n    width: 80%;\n    /* flex-direction: column; */\n  }\n  /* .full-name-div{\n        flex-direction: column;\n\n    } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* html, body {
    height: 100%;
  } */
  
  /* body {
    background: var(--Gray-24, #17171a);

  } */
  
  /* .App {
    border: 1px solid red;
    height: 100%;
    width: 100%;
  } */
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;;KAEG;;EAEH;;;KAGG;;EAEH;;;;KAIG","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  /* html, body {\n    height: 100%;\n  } */\n  \n  /* body {\n    background: var(--Gray-24, #17171a);\n\n  } */\n  \n  /* .App {\n    border: 1px solid red;\n    height: 100%;\n    width: 100%;\n  } */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
